windowReady.ready(function () {
  let width = 1580;
  let height = 880;

  let duracion = isMobile.phone ? 600 : 400;
  let debounceTimer = 200;
  let debouncedCalcularMedidas = null;

  let scroller = null;
  let capas = { anterior: null, actual: null };
  let pasos = [];
  let isReduced = null;

  let container = null;
  let sensitivity = 75;
  let projectionGlobe = d3
    .geoOrthographic()
    .scale(365)
    .center([0, 0])
    .rotate([-3.337711684132892, -46.7520939522693, 0])
    .translate([width / 2, height / 2]);

  let projectionFrance = d3
    .geoOrthographic()
    .scale(5000)
    .center([0, 0])
    .rotate([-3.337711684132892, -46.7520939522693, 0])
    .translate([width / 2, height / 2]);

  let pathGlobe = d3.geoPath().projection(projectionGlobe);
  let pathFrance = d3.geoPath().projection(projectionFrance);

  let initialScale = projectionGlobe.scale();
  let escalaColorCirculos = d3
    .scaleQuantize()
    .range(["#deebf7", "#9ecae1", "#3182bd"]);
  window.escalaColorCirculos = escalaColorCirculos;
  let escalaCirculos = d3.scaleSqrt().range([5, 50]);
  let escalaFlechas = d3.scaleLog().range([1, 20]);

  let timerGlobe = null;

  let sankeyPrincipalBox = new Box({
    size: { width: width, height: height },
    padding: { top: 100, right: 180, bottom: 50, left: 180 },
  });
  let sankeyPrincipal = d3
    .sankey()
    .nodeId((d) => d.code)
    .nodeWidth(40)
    .nodePadding(5)
    .size([
      sankeyPrincipalBox.content.width,
      sankeyPrincipalBox.content.height,
    ]);

  let sankeyCarruselBox = new Box({
    size: {
      width: sankeyPrincipalBox.width,
      height: sankeyPrincipalBox.height,
    },
    padding: sankeyPrincipalBox.padding,
    margin: sankeyPrincipalBox.margin,
  });
  let sankeyCarrusel = d3
    .sankey()
    .nodeId((d) => d.code)
    .nodeWidth(40)
    .nodePadding(5)
    .size([sankeyCarruselBox.content.width, sankeyCarruselBox.content.height]);

  let lineasCarruselBox = new Box({
    size: { width: width, height: height },
    padding: {
      top: 100,
      right: 180 + sankeyPrincipal.nodeWidth(),
      bottom: 50,
      left: 180 + sankeyPrincipal.nodeWidth(),
    },
  });

  let escalaX = d3
    .scaleLinear()
    .range([
      sankeyPrincipal.nodeWidth(),
      lineasCarruselBox.content.width + sankeyPrincipal.nodeWidth(),
    ]);
  let escalaY = d3.scaleLinear().range([lineasCarruselBox.content.height, 0]);

  let pathDomestico = d3
    .line()
    .curve(d3.curveCatmullRom.alpha(0.5))
    .x((d) => escalaX(d.year))
    .y((d) => escalaY(d.domestic));
  let pathForaneo = d3
    .line()
    .curve(d3.curveCatmullRom.alpha(0.5))
    .x((d) => escalaX(d.year))
    .y((d) => escalaY(d.foreign));

  let lineasGridBox = new Box({
    size: { width: 900, height: 900 },
    padding: { top: 100, right: 150, bottom: 50, left: 70 },
  });

  lineasGridBox = new Box({
    size: { width: 400, height: 400 },
    padding: { top: 80, right: 50, bottom: 30, left: 50 },
  });

  let escalaXGrid = d3.scaleLinear().range([0, lineasGridBox.content.width]);
  let escalaYGrid = d3.scaleLinear().range([lineasGridBox.content.height, 0]);

  let pathDomesticoGrid = d3
    .line()
    .curve(d3.curveCatmullRom.alpha(0.5))
    .x((d) => escalaXGrid(d.year))
    .y((d) => escalaYGrid(d.domestic));
  let pathForaneoGrid = d3
    .line()
    .curve(d3.curveCatmullRom.alpha(0.5))
    .x((d) => escalaXGrid(d.year))
    .y((d) => escalaYGrid(d.foreign));

  let formatter = new Intl.NumberFormat("en-IN", {});

  let svg = null;
  let defs = null;
  let gGlobo = null;
  let gMapa = null;
  let gSankey = null;

  let cajasEtiquetas = {};

  let interpoladorFranciaANodoFrancia = null;
  function crearInterpoladores(params) {
    let dFranciaPeninsular = defs.select("#peninsular-france").attr("d");
    let francia = _.find(params.sankeyFrancia.nodes, { id: "SOURCE" });
    interpoladorFranciaANodoFrancia = flubber.toRect(
      dFranciaPeninsular,
      francia.x0 + sankeyPrincipalBox.padding.left,
      francia.y0 + sankeyPrincipalBox.padding.top,
      francia.x1 - francia.x0,
      francia.y1 - francia.y0
    );
  }

  function crearScroller(params) {
    let elementos = d3.selectAll("[data-capas]");
    if (elementos.size() > 0) {
      elementos.each(function (d, i) {
        pasos.push(d3.select(this).attr("data-capas"));
      });
      scroller = scrollama();
      scroller
        .setup({
          step: "[data-capas]",
          offset: 0.9,
          debug: false,
        })
        .onStepEnter(function (r) {
          handleStepEnter(r, params);
        });
    }
  }

  function handleStepEnter(response, params) {
    let capa = response.element.getAttribute("data-capas");
    if (response.direction == "up") {
      let i = response.index;
      if (i >= 1) {
        capa = pasos[i - 1];
      }
    }
    mostrarCapas(capa, response.direction, params);
  }

  function setup() {
    container = d3.select("div[data-container='story-3-1']");
    if (container.node()) {
      isReduced =
        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
      if (isReduced) {
        duracion = 0;
      }
      setupHistoria();
      debouncedCalcularMedidas = _.debounce(calcularMedidas, debounceTimer);
      window.onresize = debouncedCalcularMedidas;
    }
  }

  function calcularMedidas() {
    calcularAlturaSVG();
    scroller.resize();
  }

  function isSmallScreen() {
    return window.innerWidth <= 1024;
  }

  function isMobileSize() {
    return window.innerWidth <= 480;
  }

  function calcularAlturaSVG() {
    let box = container.node().getBoundingClientRect();
    let root = document.documentElement;
    if (isMobileSize()) {
      root.style.setProperty(
        "--svg-height",
        `calc(8rem + ${2 * box.height}px)`
      );
    } else {
      root.style.setProperty("--svg-height", `calc(8rem + ${box.height}px)`);
    }
  }

  function setupHistoria() {
    crearSVG();
    calcularAlturaSVG();
    fetchDatos();
  }

  function crearSVG() {
    svg = container
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${width} ${height}`);

    defs = svg.append("defs");

    svg = svg.append("g").attr("class", "svg");

    gGlobo = svg.append("g").attr("id", "gGlobo");

    gMapa = svg.append("g").attr("id", "gMapa");

    gSankey = svg.append("g").attr("id", "gSankey").call(hide);
  }

  function fetchDatos() {
    //TODO:Usar el comando "points centroid" en mapshaper para agregar centroides al mapa final
    Promise.all([
      d3.json("json/story3/globe.json"),
      d3.csv("csv/story3/trips_all.csv", function (d) {
        let origen = { code: d.code_origin, name: d.country_origin };
        let destino = {
          code: d.code_destination,
          name: d.country_destination,
        };
        return {
          origen: origen,
          destino: destino,
          num: +d.number_trips,
          member: d.member === "TRUE",
        };
      }),
      d3.csv("csv/story3/trips_from_eu.csv", function (d) {
        let origen = { code: d.code_origin, name: d.country_origin };
        let destino = {
          code: d.code_destination,
          name: d.country_destination,
        };
        return {
          origen: origen,
          destino: destino,
          num: +d.number_trips,
          member: d.member === "TRUE",
        };
      }),
      d3.csv("csv/story3/trips_from_france.csv", function (d) {
        let origen = { code: d.code_origin, name: d.country_origin };
        let destino = {
          code: d.code_destination,
          name: d.country_destination,
        };
        return {
          origen: origen,
          destino: destino,
          num: +d.number_trips,
          member: d.member === "TRUE",
        };
      }),
      d3.csv("csv/story3/trips_domestic_foreign.csv", function (d) {
        return {
          code: d.code,
          country: d.country,
          year: +d.year,
          domestic: parseInt(+d.domestic),
          foreign: parseInt(+d.foreign),
        };
      }),
    ])
      .then(function ([
        mapa,
        viajes,
        viajesDesdeUE,
        viajesDesdeFrancia,
        viajesComparados,
      ]) {
        let paises = topojson.feature(mapa, mapa.objects["globe"]);
        let france = topojson.feature(mapa, mapa.objects["france"]);
        let departamentos = topojson.feature(mapa, mapa.objects["departments"]);
        let centroides = topojson.feature(mapa, mapa.objects["centroids"]);

        centroides = _.map(centroides.features, function (d) {
          let obj = {
            code: d.properties.EC_CODE,
            coords: d.geometry,
          };
          return obj;
        });

        viajesDesdeFrancia = _.filter(viajesDesdeFrancia, (d) => d.num > 0);
        viajesDesdeFrancia = _.orderBy(viajesDesdeFrancia, ["num"], ["desc"]);
        clonsole.log("viajesDesdeFrancia");
        clonsole.log(viajesDesdeFrancia);
        let pathsDesdeFrancia = _.map(viajesDesdeFrancia, function (d) {
          return {
            type: "LineString",
            coordinates: [],
            properties: {
              origen: d.origen,
              destino: d.destino,
              num: d.num,
              member: d.member,
            },
          };
        });

        escalaColorCirculos.domain(
          d3.extent(
            _.map(departamentos.features, (d) => d.properties.total_trips)
          )
        );
        escalaCirculos.domain(
          d3.extent(
            _.map(departamentos.features, (d) => d.properties.total_trips)
          )
        );
        escalaFlechas.domain(
          d3.extent(_.map(pathsDesdeFrancia, (d) => d.properties.num))
        );
        escalaX.domain(d3.extent(_.map(viajesComparados, (d) => d.year)));
        escalaXGrid.domain(escalaX.domain());

        generarObjetoSankey(viajesDesdeFrancia);
        let sankeyFrancia = sankeyPrincipal(
          generarObjetoSankey(viajesDesdeFrancia)
        );
        let viajesDesdeFranciaAgrupados = agrupaViajes(sankeyFrancia);
        sankeyFrancia = sankeyPrincipal(
          generarObjetoSankey(viajesDesdeFranciaAgrupados)
        );

        let sankeyUE = sankeyPrincipal(generarObjetoSankey(viajesDesdeUE));
        let viajesDesdeUEAgrupados = agrupaViajes(sankeyUE);
        sankeyUE = sankeyPrincipal(generarObjetoSankey(viajesDesdeUEAgrupados));

        _.each(pathsDesdeFrancia, function (d) {
          let code = d.properties.origen.code;
          let obj = _.find(centroides, (d) => d.code === code);
          d.coordinates.push(obj.coords.coordinates);

          code = d.properties.destino.code;
          obj = _.find(centroides, (d) => d.code === code);
          d.coordinates.push(obj.coords.coordinates);
        });

        let viajesPorPais = _.groupBy(viajes, function (d) {
          return d.origen.code;
        });

        let viajesComparadosPorPais = _.groupBy(viajesComparados, function (d) {
          return d.code;
        });

        let sankeysPorPais = [];
        _.each(viajesPorPais, function (v, k) {
          let objSankey = generarObjetoSankey(v);
          let sankeyPorPais = sankeyCarrusel(objSankey);
          objSankey = agrupaViajes(sankeyPorPais);
          sankeysPorPais.push(generarObjetoSankey(objSankey));
        });
        sankeysPorPais = _.orderBy(
          sankeysPorPais,
          function (d) {
            let src = _.find(d.nodes, { id: "SOURCE" });
            return src.name;
          },
          ["asc"]
        );

        let params = {
          paises: paises.features[0],
          francia: france.features[0],
          departamentos: departamentos,
          viajes: viajes,
          pathsDesdeFrancia: pathsDesdeFrancia,
          viajesDesdeFrancia: viajesDesdeFrancia,
          viajesDesdeUE: viajesDesdeUE,
          sankeyFrancia: sankeyFrancia,
          sankeyUE: sankeyUE,
          sankeysPorPais: sankeysPorPais,
          viajesComparadosPorPais: viajesComparadosPorPais,
        };

        crearGlobo(params);
        crearSankey(gSankey, sankeyPrincipal, params.sankeyFrancia);
        crearCarrusel(params.sankeysPorPais, params.viajesComparadosPorPais);
        crearMiniGrid(params, ["AT", "DE", "NL", "SI"]);
        crearGrid(params);
        iniciarMiniGrid();
        /***********************
        _.delay(actualizarMiniGrid, 1000, params, ["EL", "IT", "PL", "SK"]);
        _.delay(actualizarMiniGrid, 2000, params, ["IE", "LT", "PL", "SK"]);
        //actualizarMiniGrid(params, ["EL", "IT", "PL", "SK"]);
        //actualizarMiniGrid(params, ["IE", "LT", "PL", "SK"]);
        ************************/

        //Pasan cosas, crearScroller va hacia/hasta el final del resto de funciones
        crearInterpoladores(params);
        crearScroller(params);
        return;
        //volver
        /*
duracion = 0;
        mostrarCapas("base");
        mostrarCapas("france");
        mostrarCapas("zoom");
        mostrarCapas("globe");
        mostrarCapas("arrows");
        mostrarCapas("sankey_france");
        mostrarCapas("sankey_eu", "down", params);
        mostrarCapas("sankey_charts");
        mostrarCapas("line_charts");
        */
      })
      .catch((e) => {
        console.log("fetchDatos: failed");
        console.log(e);
      });
  }

  function agrupaViajes(sankey) {
    let arreglo = [];
    let numOtros = 0;
    let members = [];
    _.each(sankey.links, function (l) {
      let box = getCajaEtiqueta(l.target.code);
      if (l.target.y1 - l.target.y0 >= box.height) {
        arreglo.push({
          origen: { code: l.source.code, name: l.source.name },
          destino: { code: l.target.code, name: l.target.name },
          member: l.member,
          num: l.value,
        });
      } else {
        numOtros += l.value;
        members.push(l.member);
      }
    });
    if (numOtros >= 0) {
      let origen = _.find(sankey.nodes, { id: "SOURCE" });
      arreglo.push({
        origen: { code: origen.code, name: origen.name },
        destino: { code: "OTHER", name: "Other" },
        member: null,
        num: numOtros,
        agrupa: members,
      });
    }
    return arreglo;
  }

  function setCajaEtiqueta(d) {
    if (!cajasEtiquetas.hasOwnProperty(d.code)) {
      let t = svg
        .append("text")
        .attr("x", 0)
        .attr("y", width / 2)
        .style("font-size", "18px")
        .style("font-weight", 600)
        .style("font-family", "IBM Plex Sans")
        .text(d.name);
      let caja = t.node().getBBox();
      cajasEtiquetas[d.code] = caja;
      t.remove();
    }
  }

  function getCajaEtiqueta(code) {
    return cajasEtiquetas[code];
  }

  function generarObjetoSankey(viajes) {
    let nodes = {};
    let links = [];

    viajes = _.filter(viajes, (d) => d.num > 0);
    viajes = _.orderBy(viajes, ["num"], ["desc"]);
    let otros = _.remove(viajes, function (v) {
      return v.destino.code === "OTHER";
    });
    viajes = _.concat(viajes, otros);

    _.each(viajes, function (v) {
      let o = v.origen;
      setCajaEtiqueta(o);
      if (!nodes.hasOwnProperty(o.code)) {
        nodes[o.code] = {
          code: o.code,
          name: o.name,
          member: true,
          id: "SOURCE",
        };
      }

      let d = v.destino;
      setCajaEtiqueta(d);
      if (!nodes.hasOwnProperty(d.code)) {
        nodes[d.code] = {
          code: d.code,
          name: d.name,
          member: v.member,
          id: d.code,
        };
      }

      let link = {
        source: o.code,
        target: d.code,
        value: v.num,
        member: v.member,
        id: d.code,
      };

      if (v.hasOwnProperty("agrupa")) {
        link.agrupa = _.uniq(v.agrupa);
      }

      links.push(link);
    });
    return { nodes: _.values(nodes), links: links };
  }

  function crearGlobo(params) {
    let paises = params.paises;
    let france = params.francia;

    clonsole.log("paises");
    clonsole.log(paises);
    clonsole.log("france");
    clonsole.log(france);

    let departamentos = params.departamentos;
    let pathsDesdeFrancia = params.pathsDesdeFrancia;

    /*
    El código del globo salió de acá: https://observablehq.com/@michael-keith/draggable-globe-in-d3
    */
    /*
   Otra versión del globo: https://plnkr.co/edit/AzianFOknvgRBR2f7TvC?p=preview&preview
   */
    const markerBoxWidth = 20;
    const markerBoxHeight = 20;
    const refX = markerBoxWidth / 2;
    const refY = markerBoxHeight / 2;
    const markerWidth = markerBoxWidth / 2;
    const markerHeight = markerBoxHeight / 2;
    const arrowPoints = [
      [0, 0],
      [0, 20],
      [20, 10],
    ];

    defs
      .append("marker")
      .attr("id", "arrow")
      .attr("viewBox", [0, 0, 200, 200])
      .attr("refX", refX)
      .attr("refY", refY)
      .attr("markerWidth", markerBoxWidth)
      .attr("markerHeight", markerBoxHeight)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", d3.line()(arrowPoints))
      .attr("stroke", "none")
      .attr("fill", "#165ce9");

    defs
      .append("path")
      .attr("id", "peninsular-france")
      .attr("d", pathGlobe(france.geometry));

    let globo = gGlobo
      .append("circle")
      .attr("fill", "#ffffff")
      .attr("stroke", "#000000")
      .attr("stroke-width", "0.2")
      .attr("cx", width / 2)
      .attr("cy", height / 2)
      .attr("r", initialScale);

    let gCountries = gMapa.append("g").attr("class", "countries");

    gCountries
      .append("path")
      .attr("id", "countries")
      .datum(paises)
      .attr("d", pathGlobe)
      .attr("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.stroke)
      .style("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties.opacity);

    gCountries
      .append("path")
      .attr("id", "france")
      .datum(france)
      .attr("d", pathGlobe)
      .attr("fill", (d) => d.properties.fill)
      .style("stroke", (d) => d.properties.fill)
      .style("stroke-width", (d) => d.properties["stroke-width"])
      .style("opacity", (d) => d.properties.opacity);

    gMapa.append("g").attr("class", "departments").call(hide);
    gMapa.append("g").attr("class", "circles").call(hide);
    gMapa.append("g").attr("class", "trips").call(hide);
    /*
      .selectAll("g.trip")
      .data(pathsDesdeFrancia)
      .enter()
      .append("g")
      .attr("class", "trip")
      .append("path")
      .attr("d", pathGlobe)
      .style("fill", "none")
      .call(estilizarPath)
      .style("stroke-width", (d) => escalaFlechas(d.properties.num))
      .style("opacity", 0.3)
      //.attr("marker-end", "url(#arrow)")
      .each(function (d) {
        d.properties.length = d3.select(this).node().getTotalLength();
      });
*/
    /*
    gMapa.call(
      d3.drag().on("drag", (event) => {
        const rotate = projectionGlobe.rotate();
        const k = sensitivity / projectionGlobe.scale();
        projectionGlobe.rotate([
          rotate[0] + event.dx * k,
          rotate[1] - event.dy * k,
        ]);
        pathGlobe.projection(projectionGlobe);
        gMapa.selectAll("path").attr("d", pathGlobe);
      })
    )
      .call(
        d3.zoom().on("zoom", (event) => {
          if (event.transform.k > 0.3) {
            projectionGlobe.scale(initialScale * event.transform.k);
            pathGlobe.projection(projectionGlobe);
            gMapa.selectAll("path").attr("d", pathGlobe);
            console.log("scale");
            console.log(projectionGlobe.scale());
            globo.attr("r", projectionGlobe.scale());
          } else {
            event.transform.k = 0.3;
          }
        })
      );
      */
    startTimerGlobe();
  }

  function startTimerGlobe() {
    timerGlobe = d3.timer(function (elapsed) {
      let rotate = projectionGlobe.rotate();
      let k = sensitivity / projectionGlobe.scale();
      projectionGlobe.rotate([rotate[0] - 1 * k, rotate[1]]);
      pathGlobe.projection(projectionGlobe);
      gMapa.selectAll("path").attr("d", pathGlobe);
    }, 200);
  }

  function stopTimerGlobe() {
    timerGlobe.stop();
  }

  function centrarFrancia() {
    stopTimerGlobe();
    //NOTE: Hay que seguir depurando esto pero primero hauy que ajustar el scrollytelling en reversa
    //NOTE: Hay que seguir con los ajustes del ancho de Francia
    gMapa
      .select("#france")
      .transition("path-stroke")
      .duration(duracion)
      .style("stroke", (d) => d.properties.stroke)
      .end()
      .catch((e) => {
        console.log("centrarFrancia: transition('path-stroke') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa.select("#france").style("stroke", (d) => d.properties.stroke);
        //.style("stroke-width", 1);
      });

    gMapa
      .selectAll("path")
      .transition("path-center")
      .duration(duracion)
      .attrTween("d", function (d) {
        let r = d3.interpolate(
          projectionGlobe.rotate(),
          projectionFrance.rotate()
        );
        return function (t) {
          projectionGlobe.rotate(r(t));
          pathGlobe.projection(projectionGlobe);
          return pathGlobe(d);
        };
      })
      .end()
      .catch((e) => {
        console.log("centrarFrancia: transition('path-center') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa.selectAll("path").attr("d", pathGlobe);
      });
  }

  function crearDepartamentos(params, flagTransicion) {
    flagTransicion = flagTransicion || false;

    gMapa.select("g.departments").call(unhide);

    let departamentos = gMapa
      .select("g.departments")
      .selectAll("path")
      .data(params.departamentos.features, (d) => d.properties.NUTS_ID)
      .enter()
      .append("path");

    departamentos
      .attr("d", pathFrance)
      .each(function (d) {
        d.properties.centroide = pathFrance.centroid(d);
      })
      .attr("fill", "#EEEEEE")
      .style("stroke", flagTransicion ? "transparent" : "#165ce9")
      .style("stroke-width", 0.3)
      .style("opacity", 0.8);

    if (flagTransicion) {
      departamentos
        .transition("path-stroke")
        .duration(duracion)
        .style("stroke", "#165ce9")
        .end()
        .catch((e) => {
          console.log("crearDepartamentos: transition('path-stroke') failed");
          console.log(e);
        })
        .finally(function () {
          /*gMapa
            .select("g.departments")
            .selectAll("path")*/
          departamentos.style("stroke", "#165ce9");
        });
    }
  }

  function quitarDepartamentos(flagTransicion) {
    flagTransicion = flagTransicion || false;

    let departamentos = gMapa
      .select("g.departments")
      .selectAll("path")
      .data([])
      .exit();

    if (flagTransicion) {
      return new Promise(function (resolve) {
        departamentos
          .transition("path-stroke")
          .duration(duracion)
          .style("stroke", "transparent")
          .end()
          .catch((e) => {
            console.log(
              "quitarDepartamentos: transition('path-stroke') failed"
            );
            console.log(e);
          })
          .finally(function () {
            departamentos.remove();
            gMapa.select("g.departments").call(hide);
            resolve(false);
          });
      });
    } else {
      departamentos.remove();
      gMapa.select("g.departments").call(hide);
    }
  }

  function crearCirculos(params, flagTransicion) {
    flagTransicion = flagTransicion || false;

    gMapa.select("g.circles").call(unhide);

    let circulos = gMapa
      .select("g.circles")
      .selectAll("circle")
      .data(params.departamentos.features, (d) => d.properties.NUTS_ID)
      .enter()
      .append("circle");

    circulos
      .attr("r", (d) =>
        flagTransicion ? 0 : escalaCirculos(d.properties.total_trips)
      )
      .attr("cx", (d) => d.properties.centroide[0])
      .attr("cy", (d) => d.properties.centroide[1])
      .attr("fill", (d) => escalaColorCirculos(d.properties.total_trips))
      .attr("opacity", 0.6)
      .attr("stroke", "#000000")
      .attr("stroke_width", "2px");

    if (flagTransicion) {
      circulos
        .transition("circle-r")
        .duration(duracion)
        .attr("r", (d) => escalaCirculos(d.properties.total_trips))
        .end()
        .catch((e) => {
          console.log("crearCirculos: transition('circle-r') failed");
          console.log(e);
        })
        .finally(function () {
          circulos.attr("r", (d) => escalaCirculos(d.properties.total_trips));
        });
    }
  }

  function quitarCirculos(flagTransicion) {
    flagTransicion = flagTransicion || false;

    let circulos = gMapa
      .select("g.circles")
      .selectAll("circle")
      .data([])
      .exit();

    if (flagTransicion) {
      return new Promise(function (resolve) {
        circulos
          .transition("circle-r")
          .duration(duracion)
          .attr("r", 0)
          .end()
          .catch((e) => {
            console.log("quitarCirculos: transition('circle-r') failed");
            console.log(e);
          })
          .finally(function () {
            circulos.remove();
            gMapa.select("g.circles").call(hide);
            resolve(true);
          });
      });
    } else {
      circulos.remove();
      gMapa.select("g.circles").call(hide);
    }
  }

  function crearFlechas(params, flagTransicion) {
    flagTransicion = flagTransicion || false;
    let pathsDesdeFrancia = params.pathsDesdeFrancia;

    gMapa.select("g.trips").call(unhide);

    //defs.select("#arrow").select("path").style("fill", "blue");

    let flechas = gMapa
      .select("g.trips")
      .selectAll("g.trip")
      .data(pathsDesdeFrancia)
      .enter()
      .append("g")
      .attr("class", "trip");

    flechas
      .append("path")
      .attr("d", pathGlobe)
      .style("fill", "none")
      .call(estilizarPath)
      .style("stroke-width", (d) => escalaFlechas(d.properties.num))
      .style("opacity", 0.3)
      //.attr("marker-end", "url(#arrow)")
      .each(function (d) {
        d.properties.length = d3.select(this).node().getTotalLength();
      });

    if (flagTransicion) {
      return new Promise(function (resolve) {
        flechas
          .attr(
            "stroke-dasharray",
            (d) => `${d.properties.length} ${d.properties.length}`
          )
          .attr("stroke-dashoffset", (d) => d.properties.length)
          .transition("dash-offset")
          .duration(duracion)
          .attr("stroke-dashoffset", 0)
          .end()
          .catch((e) => {
            console.log("crearFlechas: transition('dash-offset') failed");
            console.log(e);
          })
          .finally(function () {
            flechas
              .attr("stroke-dashoffset", null)
              .attr("stroke-dasharray", null);
            resolve(true);
          });
      });
    } else {
      flechas.attr("stroke-dashoffset", null).attr("stroke-dasharray", null);
    }
  }

  function quitarFlechas(flagTransicion) {
    flagTransicion = flagTransicion || false;

    let flechas = gMapa.select("g.trips").selectAll("g.trip").data([]).exit();

    if (flagTransicion) {
      return new Promise(function (resolve) {
        flechas
          .transition("g.opacity")
          .duration(duracion)
          .style("opacity", 0)
          .end()
          .catch((e) => {
            console.log("quitarFlechas: transition('g.opacity') failed");
            console.log(e);
          })
          .finally(function () {
            flechas.remove();
            gMapa.select("g.trips").call(hide);
            resolve(true);
          });
      });
    } else {
      flechas.remove();
      gMapa.select("g.trips").call(hide);
    }
  }

  function resaltarFrancia(flagTransicion) {
    flagTransicion = flagTransicion || false;

    let francia = gMapa.select("#france");

    if (flagTransicion) {
      return new Promise(function (resolve) {
        francia
          .transition("path-stroke")
          .duration(duracion)
          .style("stroke-width", (d) => d.properties["stroke-width"])
          .end()
          .catch((e) => {
            console.log(
              "resaltarFrancia: transition('path-stroke-width') failed"
            );
            console.log(e);
          })
          .finally(function () {
            gMapa
              .select("#france")
              .style("stroke-width", (d) => d.properties["stroke-width"]);
            resolve(true);
          });
      });
    } else {
      gMapa
        .select("#france")
        .style("stroke-width", (d) => d.properties["stroke-width"]);
    }
  }

  function zoomFrancia(params) {
    let projectionTransition = projectionFrance;
    let pathTransition = pathFrance;

    //gMapa.select("g.departments").call(unhide);
    //gMapa.select("g.circles").call(unhide);

    gGlobo
      .select("circle")
      .transition("circle-zoom")
      .duration(duracion)
      .attr("r", projectionFrance.scale())
      .end()
      .catch((e) => {
        console.log("zoomFrancia: transition('circle-zoom') failed");
        console.log(e);
      })
      .finally(function () {
        gGlobo.select("circle").attr("r", projectionFrance.scale());
      });

    gMapa
      .select("#france")
      .transition("path-stroke-width")
      .duration(duracion)
      .style("stroke-width", 4)
      .end()
      .catch((e) => {
        console.log("zoomFrancia: transition('path-stroke-width') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa.select("#france").style("stroke-width", 4);
      });

    gMapa
      .selectAll("path")
      .transition("path-zoom")
      .duration(duracion)
      .attrTween("d", function (d) {
        let r = d3.interpolate(
          projectionGlobe.rotate(),
          projectionTransition.rotate()
        );
        let s = d3.interpolate(
          projectionGlobe.scale(),
          projectionTransition.scale()
        );
        return function (t) {
          projectionTransition.rotate(r(t)).scale(s(t));
          pathTransition.projection(projectionTransition);
          return pathTransition(d);
        };
      })
      .end()
      .catch((e) => {
        console.log("zoomFrancia: transition('path-zoom') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa.selectAll("path").attr("d", pathFrance);
        crearDepartamentos(params, true);
        crearCirculos(params, true);
      });
  }

  function mostrarGlobo() {
    //wip
    console.clear();
    console.log("mostrarGlobo");

    let projectionTransition = projectionGlobe;
    let pathTransition = pathGlobe;

    Promise.all([
      resaltarFrancia(true),
      quitarDepartamentos(true),
      quitarCirculos(true),
    ]).finally(function () {
      gGlobo
        .select("circle")
        .transition("circle-zoom")
        .duration(duracion)
        .attr("r", projectionGlobe.scale())
        .end()
        .catch((e) => {
          console.log("mostrarGlobo: transition('circle-zoom') failed");
          console.log(e);
        })
        .finally(function () {
          gGlobo.select("circle").attr("r", projectionGlobe.scale());
        });

      gMapa
        .selectAll("path")
        .transition("path-zoom")
        .duration(duracion)
        .attrTween("d", function (d) {
          let s = d3.interpolate(
            projectionFrance.scale(),
            projectionTransition.scale()
          );
          return function (t) {
            projectionTransition.scale(s(t));
            pathTransition.projection(projectionTransition);
            return pathTransition(d);
          };
        })
        .end()
        .catch((e) => {
          console.log("mostrarGlobo: transition('path-zoom') failed");
          console.log(e);
        })
        .finally(function () {
          gMapa.selectAll("path").attr("d", pathGlobe);
        });
    });
  }

  function mostrarFlechas(params) {
    startTimerGlobe();
    //defs.select("#arrow").select("path").style("fill", "blue");
    let pathsDesdeFrancia = params.pathsDesdeFrancia;

    gMapa.select("g.trips").call(unhide);

    let flechas = gMapa
      .select("g.trips")
      .selectAll("g.trip")
      .data(pathsDesdeFrancia)
      .enter()
      .append("g")
      .attr("class", "trip");

    flechas
      .append("path")
      .attr("d", pathGlobe)
      .style("fill", "none")
      .call(estilizarPath)
      .style("stroke-width", (d) => escalaFlechas(d.properties.num))
      .style("opacity", 0.3)
      //.attr("marker-end", "url(#arrow)")
      .each(function (d) {
        d.properties.length = d3.select(this).node().getTotalLength();
      });

    flechas
      .attr(
        "stroke-dasharray",
        (d) => `${d.properties.length} ${d.properties.length}`
      )
      .attr("stroke-dashoffset", (d) => d.properties.length)
      .transition("dash-offset")
      .duration(duracion)
      .attr("stroke-dashoffset", 0)
      .end()
      .catch((e) => {
        console.log("mostrarFlechas: transition('dash-offset') failed");
        console.log(e);
      })
      .finally(function () {
        flechas.attr("stroke-dashoffset", null).attr("stroke-dasharray", null);
      });
  }

  function estilizarPath(el) {
    el.style("stroke", (d) => (d.properties.member ? "#165ce9" : "#4fc9a4"));
  }

  function estilizarNodoOrigen(el) {
    el.style("fill", (d) => (d.member ? "#165ce9" : "#4fc9a4"))
      .style("stroke", (d) => (d.member ? "#165ce9" : "#4fc9a4"))
      .attr("x", (d) => d.x0)
      .attr("y", (d) => d.y0)
      .attr("height", (d) => d.y1 - d.y0);
  }

  function estilizarNodoOrigenTexto(el, ancho) {
    el.attr("x", (d) => d.x0 - 10)
      .attr("y", (d) => d.y0 + (d.y1 - d.y0) / 2)
      .style("font-size", "18px")
      .style("font-weight", 600)
      .style("font-family", "IBM Plex Sans")
      .attr("text-anchor", "end")
      .attr("dominant-baseline", "middle")
      .style("fill", (d) => (d.member ? "#165ce9" : "#4fc9a4"))
      .text((d) => d.name)
      .each(function (d) {
        d.opacity = 0;
        let box = getCajaEtiqueta(d.code);
        if (d.y1 - d.y0 >= box.height) {
          d.opacity = 1;
        }
      });
  }

  function estilizarNodo(el) {
    el.style("fill", colorearRectNode)
      .style("stroke", colorearRectNode)
      .attr("x", (d) => d.target.x0)
      .attr("y", (d) => d.target.y0)
      .attr("height", (d) => d.target.y1 - d.target.y0);
  }

  function estilizarNodoTexto(el, ancho) {
    el.attr("x", (d) => d.target.x0 + ancho + 10)
      .attr("y", (d) => d.target.y0 + (d.target.y1 - d.target.y0) / 2)
      .style("font-size", "18px")
      .style("font-weight", 600)
      .style("font-family", "IBM Plex Sans")
      .attr("text-anchor", "start")
      .attr("dominant-baseline", "middle")
      .style("fill", colorearRectNode)
      .text((d) => d.target.name)
      .each(function (d) {
        d.target.opacity = 0;
        let box = getCajaEtiqueta(d.target.code);
        if (d.target.y1 - d.target.y0 >= box.height) {
          d.target.opacity = 1;
        }
      });
  }

  function estilizarEnlace(el) {
    //function hide
    el.style("stroke", colorearPathLink)
      .style("fill", "none")
      .attr("stroke-width", function (d) {
        return d.width;
      })
      .attr("d", d3.sankeyLinkHorizontal())
      .attr("class", (d) => (d.member ? "link domestic" : "link foreign"));
  }

  function actualizarEscalaY(escala, data) {
    let domain = [
      0,
      _.max(
        _.concat(
          _.map(data, (d) => d.domestic),
          _.map(data, (d) => d.foreign)
        )
      ),
    ];
    let max = domain[1];
    max = roundUpNice(max);
    domain[1] = max;
    escala.domain(domain);
  }

  function roundUpNice(n) {
    let escala = getEscala(n);
    let temp = Math.floor(n / escala);
    if (temp < n) {
      temp += 1;
    }
    if (escala === 1) {
      if (temp % 2 === 1) {
        temp += 1;
      }
    }
    n = temp * escala;
    return n;
  }

  function getEscala(n) {
    let escala = 1;
    while (escala < n) {
      escala *= 10;
    }
    escala /= 10;
    return escala;
  }

  function crearLineas(grupoLineas, data) {
    actualizarEscalaY(escalaY, data);
    grupoLineas
      .append("g")
      .attr("class", "name")
      .call(hide)
      .append("text")
      .datum({ code: data[0].code, country: data[0].country })
      .attr("x", -60)
      .attr("y", -70)
      .style("font-size", "40px")
      .style("font-weight", 700)
      .style("fill", "black")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .text((d) => d.country);

    grupoLineas
      .append("g")
      .attr("class", "x")
      .call(d3.axisBottom(escalaX).tickFormat(d3.format("d")))
      .attr("transform", `translate(0, ${lineasCarruselBox.content.height})`)
      .call(hide)
      .selectAll("text")
      .style("font-size", "24px")
      .attr("fill", "grey");
    grupoLineas.select("g.x").select("path").attr("stroke", "transparent");

    grupoLineas
      .append("g")
      .attr("class", "y")
      .call(d3.axisLeft(escalaY))
      .attr("transform", `translate(${sankeyPrincipal.nodeWidth()}, 0)`)
      .call(hide)
      .selectAll("text")
      .style("font-size", "24px")
      .attr("fill", "grey");
    grupoLineas.select("g.y").select("path").attr("stroke", "transparent");

    grupoLineas
      .append("g")
      .attr("class", "covid ignore")
      .call(hide)
      .append("line")
      .attr("x1", escalaX(2020))
      .attr("y1", 0)
      .attr("x2", escalaX(2020))
      .attr("y2", lineasCarruselBox.content.height)
      .style("stroke", "#000000")
      .style("stroke-width", "3px")
      .style("stroke-dasharray", "5, 5");

    grupoLineas
      .select("g.covid")
      .append("text")
      .attr("x", escalaX(2020) - 20)
      .attr("y", 0)
      .style("font-size", "26px'")
      .style("font-weight", 700)
      .style("fill", "grey")
      .attr("text-anchor", "end")
      .attr("dominant-baseline", "middle")
      .text("Pandemic starts");

    grupoLineas.append("g").attr("class", "main").call(hide);
    grupoLineas.append("g").attr("class", "voronoi").call(hide);

    grupoLineas
      .select("g.main")
      .attr("fill", "none")
      .attr("stroke-linecap", "round");

    grupoLineas
      .select("g.main")
      .append("path")
      .attr("class", "domestic")
      .datum(data)
      .attr("d", pathDomestico)
      .attr("stroke-width", "5px")
      .attr("stroke", "#165ce9");

    grupoLineas
      .select("g.main")
      .append("path")
      .attr("class", "foreign")
      .datum(data)
      .attr("d", pathForaneo)
      .attr("stroke-width", "5px")
      .attr("stroke", "#4fc9a4");

    let voronoiData = [];
    _.each(data, function (d) {
      voronoiData.push({
        x: escalaX(d.year),
        y: escalaY(d.domestic),
        value: formatter.format(d.domestic),
        type: "domestic",
        year: d.year,
      });
      voronoiData.push({
        x: escalaX(d.year),
        y: escalaY(d.foreign),
        value: formatter.format(d.foreign),
        type: "foreign",
        year: d.year,
      });
    });

    let delaunay = d3.Delaunay.from(
      voronoiData,
      (d) => d.x,
      (d) => d.y
    );

    let voronoi = delaunay.voronoi([
      0,
      0,
      lineasCarruselBox.content.width + 2 * sankeyPrincipal.nodeWidth(),
      lineasCarruselBox.content.height,
    ]);

    grupoLineas
      .select("g.voronoi")
      .selectAll("path")
      .data(voronoiData)
      .enter()
      .append("path")
      .attr("d", (d, i) => voronoi.renderCell(i))
      .attr("fill", "transparent")
      .attr("stroke", "transparent")
      .on("mouseover", crearTooltipVoronoi)
      .on("mouseout", deshacerTooltipVoronoi);
  }

  //TODO: Hay que hacer una función intermedia que permita mandar las medidas de la caja de cada chart para poder limitar los tooltips al voronoi o leer las medidas del svg de la raíz
  function crearTooltipVoronoi(e, d) {
    let el = d3.select(this);
    let tipo = d.type;
    let color = tipo === "domestic" ? "#165ce9" : "#4fc9a4";
    let titulo = tipo === "domestic" ? "Domestic" : "Foreign";

    let svg = d3.select(el.node().closest("g.svg"));
    let gMain = svg.select("g.main");

    let gTooltip = gMain.append("g").attr("class", "tooltip ignore");

    let rect = gTooltip
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0)
      .attr("height", 0)
      .style("fill", "#ffffff");

    let text = gTooltip
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", 0)
      .style("font-size", "24px")
      .style("font-weight", 400)
      .attr("text-anchor", "middle")
      .style("fill", "black");

    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", 0)
      .style("font-weight", 700)
      .style("fill", color)
      .text(`${titulo} country`);
    text
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .style("font-weight", 700)
      .text(`${d.year}: `);
    text.append("tspan").text(d.value);

    let box = text.node().getBBox();

    gTooltip.attr("transform", `translate(${d.x}, ${d.y})`);

    rect
      .attr("x", box.x - 5)
      .attr("y", box.y - 5)
      .attr("width", box.width + 10)
      .attr("height", box.height + 10);
  }

  function deshacerTooltipVoronoi(e, d) {
    let el = d3.select(this);
    let svg = d3.select(el.node().closest("g.svg"));
    let gMain = svg.select("g.main");
    gMain.selectAll("g.tooltip").remove();
  }

  function crearSankey(grupoSankey, sankey, data) {
    let src = _.find(data.nodes, { id: "SOURCE" });

    grupoSankey.attr(
      "transform",
      `translate(${sankeyPrincipalBox.padding.left},${sankeyPrincipalBox.padding.top})`
    );
    grupoSankey.append("g").attr("class", "origin").datum(src).call(hide);
    grupoSankey.append("g").attr("class", "trips").call(hide);

    grupoSankey
      .select("g.origin")
      .append("rect")
      .call(estilizarNodoOrigen)
      .attr("width", sankey.nodeWidth());

    grupoSankey
      .select("g.origin")
      .append("text")
      .text((d) => d.name)
      .call(estilizarNodoOrigenTexto, sankey.nodeWidth())
      .style("opacity", (d) => d.opacity);
  }

  function mostrarSankeyInicial(params) {
    stopTimerGlobe();
    centrarFrancia();
    gSankey.select("g.origin").style("display", null).style("opacity", 0);
    gSankey.select("g.trips").style("display", null).style("opacity", 0);

    let francia = gSankey
      .append("path")
      .attr("d", d3.select("#peninsular-france").attr("d"))
      .attr(
        "transform",
        `translate(-${sankeyPrincipalBox.padding.left}, -${sankeyPrincipalBox.padding.top})`
      )
      .style("fill", "#EEEEEE")
      .style("stroke", "black")
      .style("stroke-width", 1)
      .style("opacity", 0.8);

    gGlobo
      .transition("gGlobo")
      .duration(duracion)
      .delay(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("mostrarSankeyInicial: transition('gGlobo') failed");
        console.log(e);
      })
      .finally(function () {
        gGlobo.call(hide);
        gMapa.select("g.trips").call(hide);
      });

    gMapa
      .transition("gMapa")
      .duration(duracion)
      .delay(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("mostrarSankeyInicial: transition('gMapa') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa.call(hide);
      });

    gSankey
      .style("display", null)
      .transition("gSankey")
      .duration(duracion)
      .delay(duracion)
      .style("opacity", 1)
      .end()
      .catch((e) => {
        console.log("mostrarSankeyInicial: transition('gSankey') failed");
        console.log(e);
      })
      .finally(function () {
        gSankey.style("opacity", null);
        francia
          .transition("toFrance")
          .duration(duracion)
          .attrTween("d", function () {
            return interpoladorFranciaANodoFrancia;
          })
          .style("fill", "#165ce9")
          .style("stroke", "#165ce9")
          .style("stroke-width", 1)
          .style("opacity", 1)
          .end()
          .catch((e) => {
            console.log("mostrarSankeyInicial: toFrance failed");
            console.log(e);
          })
          .finally(function () {
            francia.remove();
            gSankey.select("g.origin").style("opacity", null);
            gSankey.select("g.trips").style("opacity", null);
            actualizarSankey(gSankey, sankeyPrincipal, params.sankeyFrancia);
          });
      });
  }

  function actualizarSankey(grupoSankey, sankey, data) {
    let src = _.find(data.nodes, { id: "SOURCE" });
    grupoSankey.select("g.origin").datum(src);
    grupoSankey
      .select("g.origin")
      .select("rect")
      .transition("g.origin_rect")
      .duration(duracion * 1.4)
      .attr("height", (d) => d.y1 - d.y0)
      .attr("y", (d) => d.y0)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('g.origin_rect') failed");
        console.log(e);
      })
      .finally(function () {
        grupoSankey
          .select("g.origin")
          .select("rect")
          .attr("height", (d) => d.y1 - d.y0)
          .attr("y", (d) => d.y0);
      });

    grupoSankey
      .select("g.origin")
      .select("text")
      .text((d) => d.name)
      .transition("g.origin_text")
      .duration(duracion * 1.4)
      .attr("y", (d) => d.y0 + (d.y1 - d.y0) / 2)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('g.origin_text') failed");
        console.log(e);
      })
      .finally(function () {
        grupoSankey
          .select("g.origin")
          .select("text")
          .attr("y", (d) => d.y0 + (d.y1 - d.y0) / 2);
      });

    let otherTrip = _.find(data.links, { id: "OTHER" }) || false;
    if (otherTrip) {
      if (otherTrip.hasOwnProperty("agrupa")) {
        let otherTrips = _.remove(data.links, { id: "OTHER" });
        let otherNodes = _.remove(data.nodes, { id: "OTHER" });

        if (otherTrips.length > 0) {
          let other = otherTrips[0];
          let index = other.index;
          let members = other.agrupa;
          delete other.agrupa;

          let otherN = otherNodes[0];
          let indexN = otherN.index;

          _.each(members, function (member) {
            let nuevo = _.clone(other);
            nuevo.index = index;
            nuevo.member = member;
            nuevo.other = true;
            nuevo.id = member === true ? "OTHERMEMBER" : "OTHERNONMEMBER";
            index++;

            let nuevoNodo = _.clone(otherN);
            nuevoNodo.index = indexN;
            nuevoNodo.member = member;
            nuevoNodo.other = true;
            nuevoNodo.id = member === true ? "OTHERMEMBER" : "OTHERNONMEMBER";
            indexN++;

            nuevo.target = nuevoNodo;
            nuevoNodo.targetLinks = [nuevo];

            data.links.push(nuevo);
            data.nodes.push(nuevoNodo);
          });
        }
      }
    }

    let trips = grupoSankey
      .select("g.trips")
      .selectAll("g.trip")
      .data(data.links, (d) => d.id);
    let tripsEnter = trips.enter();
    let tripsExit = trips.exit();

    let gruposEnter = tripsEnter.append("g").attr("class", "trip");

    let pathEnter = gruposEnter
      .append("path")
      .attr("class", "link")
      .call(estilizarEnlace)
      .each(function (d) {
        d.length = d3.select(this).node().getTotalLength();
      })
      .attr("stroke-dasharray", (d) => `${d.length} ${d.length}`)
      .attr("stroke-dashoffset", (d) => d.length);

    let rectEnter = gruposEnter
      .append("rect")
      .attr("class", "destination")
      .call(estilizarNodo)
      .attr("width", 0);

    let textEnter = gruposEnter
      .append("text")
      .attr("class", "destination")
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", 0);

    pathEnter
      .transition("path.link_enter")
      .duration(duracion * 0.7)
      .attr("stroke-dashoffset", 0)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('path.link_enter') failed");
        console.log(e);
      })
      .finally(function () {
        pathEnter
          .attr("stroke-dashoffset", null)
          .attr("stroke-dasharray", null);

        //NOTE: ¿mouseenter/mouseleave funcionan parecido en móvil?
        gruposEnter
          //.on("click", crearTooltipSankey)
          //.on("dblclick", deshacerTooltipSankey);
          .on("mouseenter", crearTooltipSankey)
          .on("mouseleave", deshacerTooltipSankey);
        //.on("mouseover", crearTooltipSankey)
        //.on("mouseout", deshacerTooltipSankey);

        rectEnter
          .transition("rect_enter")
          .duration(duracion * 0.3)
          .attr("width", sankey.nodeWidth())
          .end()
          .catch((e) => {
            console.log("actualizarSankey: transition('rect_enter') failed");
            console.log(e);
          })
          .finally(function () {
            rectEnter.attr("width", sankey.nodeWidth());
          });

        textEnter
          .style("opacity", 0)
          .transition("text_enter")
          .duration(duracion * 0.3)
          .style("opacity", (d) => d.target.opacity)
          .end()
          .catch((e) => {
            console.log("actualizarSankey: transition('text_enter') failed");
            console.log(e);
          })
          .finally(function () {
            textEnter.style("opacity", (d) =>
              d.target.opacity === 1 ? null : d.target.opacity
            );
          });
      });

    let pathUpdate = trips.select("path.link");
    let rectUpdate = trips.select("rect.destination");
    let textUpdate = trips.select("text.destination");

    pathUpdate
      .transition("path.link_update")
      .duration(duracion)
      .call(estilizarEnlace)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('path.link_update') failed");
        console.log(e);
      })
      .finally(function () {
        pathUpdate.call(estilizarEnlace).each(function (d) {
          d.length = d3.select(this).node().getTotalLength();
        });
      });

    rectUpdate
      .transition("rect_update")
      .duration(duracion)
      .call(estilizarNodo)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('rect_update') failed");
        console.log(e);
      })
      .finally(function () {
        rectUpdate.call(estilizarNodo);
      });

    textUpdate
      .text((d) => d.target.name)
      .transition("text_update")
      .duration(duracion)
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", (d) => d.target.opacity)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('text_update') failed");
        console.log(e);
      })
      .finally(function () {
        textUpdate
          .call(estilizarNodoTexto, sankey.nodeWidth())
          .style("opacity", (d) => d.target.opacity);
      });

    tripsExit
      .style("opacity", 1)
      .transition("trip_remove")
      .duration(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("actualizarSankey: transition('trip_remove') failed");
        console.log(e);
      })
      .finally(function () {
        tripsExit.style("opacity", 0).remove();
      });
  }

  function colorearPathLink(d) {
    //function hide
    let other = d.other || false;
    let color = d.member ? "#447dee" : "#73d4b7";
    color = other === false ? color : "#c8c8c8";
    return color;
  }

  function colorearRectNode(d) {
    //function hide
    let other = d.other || false;
    let color = d.target.member ? "#165ce9" : "#4fc9a4";
    color = other === false ? color : "#a0a0a0";
    return color;
  }

  function colorearPathLinkHover(d, id) {
    //function hide
    let other = d.other || false;
    let color = d.member ? "#d0defb" : "#e8f8f3";
    color = other === false ? color : "#d8d8d8";
    if (d.id === id) {
      color = d.member ? "#447dee" : "#73d4b7";
      color = other === false ? color : "#c8c8c8";
    }
    return color;
  }

  function colorearRectNodeHover(d, id) {
    //function hide
    let other = d.other || false;
    let color = d.target.member ? "#a2bef6" : "#c4ede1";
    color = other === false ? color : "#b0b0b0";
    if (d.target.id === id) {
      color = d.target.member ? "#165ce9" : "#4fc9a4";
      color = other === false ? color : "#a0a0a0";
    }
    return color;
  }

  function crearTooltipSankey(e, d) {
    let trip = d3.select(this);
    let trips = d3.select(trip.node().closest("g.trips"));

    let link = trip.select("path");
    let punto = link.node().getPointAtLength(d.length / 2);

    trips
      .append("text")
      .attr("class", "tooltip ignore")
      .attr("x", punto.x)
      .attr("y", punto.y)
      .style("font-size", "30px")
      .style("font-weight", 700)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("fill", "#000000")
      .style("stroke", "#ffffff")
      .style("stroke-width", "1px")
      .text(formatter.format(d.value));

    let id = d.id;
    let estilizarPathLinkHoverConId = function (d) {
      return colorearPathLinkHover(d, id);
    };
    let estilizarRectNodeHoverConId = function (d) {
      return colorearRectNodeHover(d, id);
    };

    trips
      .selectAll("g.trip")
      .select("path.link")
      .transition("path.link_tooltip")
      .duration(duracion * 1)
      .style("stroke", estilizarPathLinkHoverConId)
      .end()
      .catch((e) => {
        /*
        console.log(
          "crearTooltipSankey: transition('path.link_tooltip') failed"
        );
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("path.link")
          .style("stroke", estilizarPathLinkHoverConId);
      });

    trips
      .selectAll("g.trip")
      .select("rect.destination")
      .transition("rect_tooltip")
      .duration(duracion * 1)
      .style("fill", estilizarRectNodeHoverConId)
      .style("stroke", estilizarRectNodeHoverConId)
      .end()
      .catch((e) => {
        /*
        console.log("crearTooltipSankey: transition('rect_tooltip') failed");
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("rect.destination")
          .style("fill", estilizarRectNodeHoverConId)
          .style("stroke", estilizarRectNodeHoverConId);
      });

    trips
      .selectAll("g.trip")
      .select("text.destination")
      .transition("text_tooltip")
      .duration(duracion * 1)
      .style("opacity", (d) => (d.target.id === id ? 1 : 0))
      .end()
      .catch((e) => {
        /*
        console.log("crearTooltipSankey: transition('text_tooltip') failed");
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("text.destination")
          .style("opacity", (d) => (d.target.id === id ? 1 : 0));
      });
  }

  function deshacerTooltipSankey(e, d) {
    let trip = d3.select(this);
    let trips = d3.select(trip.node().closest("g.trips"));

    trips.select("text.tooltip").remove();

    trips
      .selectAll("g.trip")
      .select("path.link")
      .transition("path.link_tooltip")
      .duration(duracion * 1)
      .style("stroke", colorearPathLink)
      .end()
      .catch((e) => {
        /*
        console.log(
          "deshacerTooltipSankey: transition('path.link_tooltip') failed"
        );
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("path.link")
          .style("stroke", colorearPathLink);
      });

    trips
      .selectAll("g.trip")
      .select("rect.destination")
      .transition("rect_tooltip")
      .duration(duracion * 1)
      .style("fill", colorearRectNode)
      .style("stroke", colorearRectNode)
      .end()
      .catch((e) => {
        /*
        console.log("deshacerTooltipSankey: transition('rect_tooltip') failed");
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("rect.destination")
          .style("fill", colorearRectNode)
          .style("stroke", colorearRectNode);
      });

    trips
      .selectAll("g.trip")
      .select("text.destination")
      .transition("text_tooltip")
      .duration(duracion * 1)
      .style("opacity", (d) => d.target.opacity)
      .end()
      .catch((e) => {
        /*
        console.log("deshacerTooltipSankey: transition('text_tooltip') failed");
        console.log(e);
        */
      })
      .finally(function () {
        trips
          .selectAll("g.trip")
          .select("text.destination")
          .style("opacity", (d) => d.target.opacity);
      });
  }

  function actualizarSankeySinTransicion(grupoSankey, sankey, data) {
    let src = _.find(data.nodes, { id: "SOURCE" });
    grupoSankey.select("g.origin").datum(src);

    grupoSankey
      .select("g.origin")
      .select("rect")
      .attr("height", (d) => d.y1 - d.y0);

    grupoSankey
      .select("g.origin")
      .select("text")
      .text((d) => d.name)
      .attr("y", (d) => d.y0 + (d.y1 - d.y0) / 2);

    let trips = grupoSankey
      .select("g.trips")
      .selectAll("g.trip")
      .data(data.links, (d) => d.id);
    let tripsEnter = trips.enter();
    let tripsExit = trips.exit();

    let gruposEnter = tripsEnter.append("g").attr("class", "trip");

    let pathEnter = gruposEnter
      .append("path")
      .attr("class", "link")
      .call(estilizarEnlace)
      .each(function (d) {
        d.length = d3.select(this).node().getTotalLength();
      })
      .attr("stroke-dasharray", (d) => `${d.length} ${d.length}`)
      .attr("stroke-dashoffset", (d) => d.length);

    let rectEnter = gruposEnter
      .append("rect")
      .attr("class", "destination")
      .call(estilizarNodo)
      .attr("width", 0);

    let textEnter = gruposEnter
      .append("text")
      .attr("class", "destination")
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", 0);

    pathEnter.attr("stroke-dashoffset", null).attr("stroke-dasharray", null);

    gruposEnter
      .on("mouseenter", crearTooltipSankey)
      .on("mouseleave", deshacerTooltipSankey);
    //.on("mouseover", crearTooltipSankey)
    //.on("mouseout", deshacerTooltipSankey);

    rectEnter.attr("width", sankey.nodeWidth());

    textEnter.style("opacity", (d) =>
      d.target.opacity === 1 ? null : d.target.opacity
    );

    let pathUpdate = trips.select("path.link");
    let rectUpdate = trips.select("rect.destination");
    let textUpdate = trips.select("text.destination");

    pathUpdate.call(estilizarEnlace).each(function (d) {
      d.length = d3.select(this).node().getTotalLength();
    });

    rectUpdate.call(estilizarNodo);

    textUpdate
      .text((d) => d.target.name)
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", (d) => d.target.opacity);

    tripsExit.style("opacity", 0).remove();
    /*
    let links = grupoSankey
      .select("g.links")
      .selectAll(".link")
      .data(data.links, (d) => d.id);

    let nodes = grupoSankey
      .select("g.nodes")
      .selectAll(".node")
      .data(data.nodes, (d) => d.id);

    let grupos = nodes.enter().append("g").attr("class", "node");

    grupos.append("rect").call(estilizarNodo).attr("width", sankey.nodeWidth());

    grupos
      .append("text")
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", (d) => (d.opacity === 1 ? null : d.opacity));

    let enlaces = links
      .enter()
      .append("path")
      .attr("class", "link")
      .call(estilizarEnlace)
      .each(function (d) {
        d.length = d3.select(this).node().getTotalLength();
      });

    links.call(estilizarEnlace);

    links.exit().remove();

    nodes.select("rect").call(estilizarNodo);

    nodes
      .select("text")
      .text((d) => d.name)
      .call(estilizarNodoTexto, sankey.nodeWidth())
      .style("opacity", (d) => d.opacity);

    nodes.exit().remove();
    */
  }

  function crearCarrusel(sankeysPorPais, viajesComparadosPorPais) {
    let div = d3.select("div[data-container='story-3-2']");

    let contenedorCarrusel = div.select("div.carrousel");
    _.each(sankeysPorPais, function (s) {
      let source = _.find(s.nodes, { id: "SOURCE" });

      s = sankeyCarrusel(s);

      let svg = contenedorCarrusel
        .append("div")
        .attr("data-clave", "")
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid")
        .attr(
          "viewBox",
          `0 0 ${sankeyCarruselBox.exterior.width} ${sankeyCarruselBox.exterior.height}`
        );

      svg = svg
        .append("g")
        .attr("class", "svg")
        .attr("data-src", source.code)
        .attr(
          "transform",
          `translate(${sankeyCarruselBox.padding.left}, ${sankeyCarruselBox.padding.top})`
        );

      d3.select(svg.node().closest("svg")).datum({
        sankey: s,
        viajes: viajesComparadosPorPais[source.code],
      });
      //TODO: AGREGAR ALGO A SVG PARA QUE GUARDE TAMBIËN INFO DE LAS LINEAS/PATHS PARA PODER IR Y REGRESAR ENTRE DIAGRAMAS

      crearSankey(svg, sankeyCarrusel, s);

      crearLineas(svg, viajesComparadosPorPais[source.code]);
      svg.select("g.origin").call(unhide); //NOTE: g.links + g.nodes --> g.trips
      svg.select("g.trips").call(unhide); //NOTE: g.links + g.nodes --> g.trips

      //svg.select("g.links").call(unhide);
      //svg.select("g.nodes").call(unhide);
      actualizarSankeySinTransicion(svg, sankeyCarrusel, s);
    });
    div.call(hide);
  }

  function actualizarSankeysPorPais(data) {
    let grupos = d3
      .select("div[data-container='story-3-2']")
      .selectAll("g.svg");

    grupos.each(function (d) {
      actualizarSankeySinTransicion(d3.select(this), sankeyCarrusel, data);
    });
    d3.select("div[data-container='story-3-1']").call(hide);
    d3.select("div[data-container='story-3-2']").call(unhide);

    grupos.each(function (d) {
      let dSVG = d3.select(d3.select(this).node().closest("svg")).datum();
      actualizarSankey(d3.select(this), sankeyCarrusel, dSVG.sankey);
    });
  }

  function sankeysPorPaisALineas() {
    let grupos = d3
      .select("div[data-container='story-3-2']")
      .selectAll("g.svg");

    grupos.each(function (d) {
      let g = d3.select(this);
      let dSVG = d3.select(g.node().closest("svg")).datum();

      actualizarEscalaY(escalaY, dSVG.viajes);

      let destinoDomestico = pathDomestico(dSVG.viajes);
      let destinoForaneo = pathForaneo(dSVG.viajes);

      let paths = g.select("g.trips").selectAll("path");

      paths.each(function (d) {
        let el = d3.select(this);
        let destinoPath = this.classList.contains("domestic")
          ? destinoDomestico
          : destinoForaneo;

        d.paths = {
          sankey: { d: el.attr("d"), strokeWidth: el.attr("stroke-width") },
          linea: { d: destinoPath },
          interpolador: null,
        };
        d.paths.interpolador = d3.interpolatePath(
          d.paths.sankey.d,
          destinoPath
        );
      });

      g.select("g.origin").call(hide);
      g.select("g.trips").selectAll("rect").call(hide);
      g.select("g.trips").selectAll("text").call(hide);
      g.select("g.name").call(unhide);
      g.select("g.x").call(unhide);
      g.select("g.y").call(unhide);

      paths
        .classed("ignore", true)
        .transition("path")
        .duration(duracion)
        .attrTween("d", (f) => f.paths.interpolador)
        .attr("stroke-width", 5)
        .style("stroke", (d) => (d.member ? "#447dee" : "#73d4b7"))
        .end()
        .catch((e) => {
          console.log("sankeysPorPaisALineas: transition('path') failed");
          console.log(e);
        })
        .finally(function () {
          g.select("g.trips").call(hide);
          g.select("g.trips").selectAll("rect").call(unhide);
          g.select("g.trips").selectAll("text").call(unhide);
          g.select("g.main").call(unhide);
          g.select("g.voronoi").call(unhide);
          g.select("g.covid").call(unhide);
          paths
            .classed("ignore", false)
            .attr("d", (d) => d.paths.sankey.d)
            .attr("stroke-width", (d) => d.paths.sankey.strokeWidth)
            .style("stroke", colorearPathLink);
          paths.each(function (d) {
            d.paths.interpolador = null;
          });
        });
    });
  }

  function crearGrid(params) {
    console.log("crearGrid");
    console.log("params");
    console.log(params);

    let contenedorGrid = d3
      .select("div[data-container='story-3-4']")
      .select("div.grid");

    crearSVGLineasGrid(
      contenedorGrid,
      _.values(params.viajesComparadosPorPais)
    );

    contenedorGrid.selectAll("g.name").call(unhide);
    contenedorGrid.selectAll("g.x").call(unhide);
    contenedorGrid.selectAll("g.y").call(unhide);
    contenedorGrid.selectAll("g.main").call(unhide);
    contenedorGrid.selectAll("g.voronoi").call(unhide);
    contenedorGrid.selectAll("g.covid").call(unhide);
  }

  function crearSVGLineasGrid(contenedorGrid, paises) {
    _.each(paises, function (pais) {
      let svg = contenedorGrid
        .append("div")
        .attr("data-clave", "")
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid")
        .attr(
          "viewBox",
          `0 0 ${lineasGridBox.exterior.width} ${lineasGridBox.exterior.height}`
        );

      svg = svg
        .append("g")
        .attr("class", "svg")
        .attr(
          "transform",
          `translate(${lineasGridBox.padding.left}, ${lineasGridBox.padding.top})`
        )
        .datum({
          viajes: pais,
        });

      crearLineasGrid(svg);
    });
  }

  function crearMiniGrid(params, codigos) {
    let paises = getViajesPorCodigo(codigos, params.viajesComparadosPorPais);

    console.log("crearMiniGrid");
    console.log("paises");
    console.log(paises);

    let div = d3.select("div[data-container='story-3-3']");
    let contenedorGrid = div.select("div.grid");
    crearSVGLineasGrid(contenedorGrid, paises);
    div.call(hide);
  }

  function getViajesPorCodigo(codigos, viajes) {
    let arreglo = [];
    _.each(codigos, function (c) {
      arreglo.push(viajes[c]);
    });
    return arreglo;
  }

  function crearLineasGrid(grupoLineas) {
    let data = grupoLineas.data()[0].viajes;
    actualizarEscalaY(escalaYGrid, data);
    grupoLineas
      .append("g")
      .attr("class", "name")
      .style("font-size", "30px")
      .style("font-weight", 700)
      .style("fill", "black")
      .attr("text-anchor", "start")
      .attr("dominant-baseline", "middle")
      .call(hide)
      .append("text")
      .datum({ code: data[0].code, country: data[0].country })
      .attr("x", -lineasGridBox.padding.left)
      .attr("y", -40)
      .text((d) => d.country);

    grupoLineas
      .append("g")
      .attr("class", "x")
      .style("font-size", "18px")
      .attr("fill", "grey")
      .call(
        d3
          .axisBottom(escalaXGrid)
          .tickValues([2013, 2016, 2019, 2022])
          .tickFormat(d3.format("d"))
      )
      .attr(
        "transform",
        `translate(${lineasGridBox.padding.left}, ${lineasGridBox.content.height})`
      )
      .call(hide)
      .select("path")
      .attr("stroke", "transparent");

    let domain = escalaYGrid.domain();
    let n = domain[1];
    grupoLineas
      .append("g")
      .attr("class", "y")
      .style("font-size", "18px")
      .attr("fill", "grey")
      .call(
        d3.axisLeft(escalaYGrid).tickValues([0, n / 4, n / 2, (n * 3) / 4, n])
      )
      .attr("transform", `translate(${lineasGridBox.padding.left}, 0)`)
      .call(hide)
      .select("path")
      .attr("stroke", "transparent");

    grupoLineas
      .append("g")
      .attr("class", "covid ignore")
      .attr("transform", `translate(${lineasGridBox.padding.left}, 0)`)
      .call(hide)
      .append("line")
      .attr("x1", escalaXGrid(2020))
      .attr("y1", 0)
      .attr("x2", escalaXGrid(2020))
      .attr("y2", lineasGridBox.content.height)
      .style("stroke", "#000000")
      .style("stroke-width", "3px")
      .style("stroke-dasharray", "5, 5");

    grupoLineas
      .select("g.covid")
      .style("font-size", "24px")
      .style("font-weight", 700)
      .style("fill", "grey")
      .attr("text-anchor", "end")
      .attr("dominant-baseline", "middle")
      .append("text")
      .attr("x", escalaXGrid(2020) - 10)
      .attr("y", 0)
      .text("Pandemic starts");

    grupoLineas
      .append("g")
      .attr("class", "main")
      .attr("transform", `translate(${lineasGridBox.padding.left}, 0)`)
      .call(hide);
    grupoLineas
      .append("g")
      .attr("class", "voronoi")
      .attr("transform", `translate(${lineasGridBox.padding.left}, 0)`)
      .call(hide);

    grupoLineas
      .select("g.main")
      .attr("fill", "none")
      .attr("stroke-linecap", "round");

    grupoLineas
      .select("g.main")
      .append("path")
      .attr("class", "domestic")
      .datum(data)
      .attr("d", pathDomesticoGrid)
      .attr("stroke-width", "3px")
      .attr("stroke", "#165ce9");

    grupoLineas
      .select("g.main")
      .append("path")
      .attr("class", "foreign")
      .datum(data)
      .attr("d", pathForaneoGrid)
      .attr("stroke-width", "3px")
      .attr("stroke", "#4fc9a4");

    let voronoiData = [];
    _.each(data, function (d) {
      voronoiData.push({
        x: escalaXGrid(d.year),
        y: escalaYGrid(d.domestic),
        value: formatter.format(d.domestic),
        type: "domestic",
        year: d.year,
      });
      voronoiData.push({
        x: escalaXGrid(d.year),
        y: escalaYGrid(d.foreign),
        value: formatter.format(d.foreign),
        type: "foreign",
        year: d.year,
      });
    });

    let delaunay = d3.Delaunay.from(
      voronoiData,
      (d) => d.x,
      (d) => d.y
    );

    let voronoi = delaunay.voronoi([
      0,
      0,
      lineasGridBox.content.width,
      lineasGridBox.content.height,
    ]);

    grupoLineas
      .select("g.voronoi")
      .selectAll("path")
      .data(voronoiData)
      .enter()
      .append("path")
      .attr("d", (d, i) => voronoi.renderCell(i))
      .attr("fill", "transparent")
      .attr("stroke", "transparent")
      .on("mouseover", crearTooltipVoronoi)
      .on("mouseout", deshacerTooltipVoronoi);
  }

  function iniciarMiniGrid() {
    let grupos = d3
      .select("div[data-container='story-3-3']")
      .selectAll("g.svg");
    grupos.each(function (d) {
      iniciarLineasGrid(d3.select(this));
    });
  }

  function iniciarLineasGrid(grupoLineas) {
    grupoLineas
      .select("g.main")
      .selectAll("path")
      .attr("stroke-dasharray", function (d) {
        let length = d3.select(this).node().getTotalLength();
        return `${length} ${length}`;
      })
      .attr("stroke-dashoffset", function (d) {
        return d3.select(this).node().getTotalLength();
      })
      .transition("dash-offset")
      .duration(duracion)
      .attr("stroke-dashoffset", 0)
      .end()
      .catch((e) => {
        console.log("iniciarLineasGrid: transition('dash-offset') failed");
        console.log(e);
      })
      .finally(function () {
        grupoLineas
          .select("g.main")
          .selectAll("path")
          .attr("stroke-dashoffset", null)
          .attr("stroke-dasharray", null);
      });
  }

  function terminarLineasGrid(grupoLineas) {
    grupoLineas.select("g.name").select("text").text("");
    grupoLineas.select("g.y").call(hide);
    grupoLineas.select("g.voronoi").selectAll("path").remove();

    grupoLineas
      .select("g.main")
      .selectAll("path")
      .attr("stroke-dasharray", function (d) {
        let length = d3.select(this).node().getTotalLength();
        return `${length} ${length}`;
      })
      .attr("stroke-dashoffset", 0)
      .transition("dash-offset")
      .duration(duracion)
      .attr("stroke-dashoffset", function (d) {
        return d3.select(this).node().getTotalLength();
      })
      .end()
      .catch((e) => {
        console.log("mostrarFlechas: transition('dash-offset') failed");
        console.log(e);
      })
      .finally(function () {
        gMapa
          .select("g.trips")
          .selectAll("path")
          .attr("stroke-dashoffset", function (d) {
            return d3.select(this).node().getTotalLength();
          })
          .attr("stroke-dasharray", function (d) {
            let length = d3.select(this).node().getTotalLength();
            return `${length} ${length}`;
          });
      });
  }

  function actualizarMiniGrid(params, codigos) {
    let paises = getViajesPorCodigo(codigos, params.viajesComparadosPorPais);
    console
      .log[d3.select("div[data-container='story-3-3']").selectAll("g.svg")];
    let grupos = d3
      .select("div[data-container='story-3-3']")
      .selectAll("g.svg")
      .data(paises);

    grupos.each(function (d) {
      actualizarLineasGrid(d3.select(this));
    });
  }

  function actualizarMiniGridCelda(params, codigo, celda) {
    let paises = getViajesPorCodigo([codigo], params.viajesComparadosPorPais);
    d3.select("div[data-container='story-3-3']")
      .selectAll("g.svg")
      .filter((d, i) => i === celda)
      .data(paises)
      .call(actualizarLineasGrid);
  }

  function actualizarLineasGrid(grupoLineas) {
    let data = grupoLineas.data()[0];
    actualizarEscalaY(escalaYGrid, data);
    actualizarAccesoriosLineasGrid(grupoLineas, data);

    grupoLineas
      .select("g.main")
      .selectAll("path.domestic")
      .data([data, data])
      .transition("path")
      .duration(duracion)
      .attr("d", pathDomesticoGrid)
      .end()
      .catch((e) => {
        console.log("actualizarLineasGrid: transition('path.domestic') failed");
        console.log(e);
      })
      .finally(function () {
        actualizarEscalaY(escalaYGrid, data);
        grupoLineas
          .select("g.main")
          .selectAll("path.domestic")
          .attr("d", pathDomesticoGrid);
      });

    grupoLineas
      .select("g.main")
      .selectAll("path.foreign")
      .data([data, data])
      .transition("path")
      .duration(duracion)
      .attr("d", pathForaneoGrid)
      .end()
      .catch((e) => {
        console.log("actualizarLineasGrid: transition('path.foreign') failed");
        console.log(e);
      })
      .finally(function () {
        actualizarEscalaY(escalaYGrid, data);
        grupoLineas
          .select("g.main")
          .selectAll("path.foreign")
          .attr("d", pathForaneoGrid);
      });

    //wip
    let voronoiData = [];
    _.each(data, function (d) {
      voronoiData.push({
        x: escalaXGrid(d.year),
        y: escalaYGrid(d.domestic),
        value: formatter.format(d.domestic),
        type: "domestic",
        year: d.year,
      });
      voronoiData.push({
        x: escalaXGrid(d.year),
        y: escalaYGrid(d.foreign),
        value: formatter.format(d.foreign),
        type: "foreign",
        year: d.year,
      });
    });

    let delaunay = d3.Delaunay.from(
      voronoiData,
      (d) => d.x,
      (d) => d.y
    );

    let voronoi = delaunay.voronoi([
      0,
      0,
      lineasGridBox.content.width,
      lineasGridBox.content.height,
    ]);

    grupoLineas.select("g.voronoi").selectAll("path").remove();

    grupoLineas
      .select("g.voronoi")
      .selectAll("path")
      .data(voronoiData)
      .enter()
      .append("path")
      .attr("d", (d, i) => voronoi.renderCell(i))
      .attr("fill", "transparent")
      .attr("stroke", "transparent")
      .on("mouseover", crearTooltipVoronoi)
      .on("mouseout", deshacerTooltipVoronoi);
  }

  function actualizarAccesoriosLineasGrid(grupoLineas, data) {
    grupoLineas
      .select("g.name")
      .select("text")
      .datum({ code: data[0].code, country: data[0].country })
      .text((d) => d.country);

    grupoLineas
      .select("g.x")
      .transition("g.x")
      .duration(duracion)
      .call(
        d3
          .axisBottom(escalaXGrid)
          .tickValues([2013, 2016, 2019, 2022])
          .tickFormat(d3.format("d"))
      )
      .end()
      .catch((e) => {
        console.log("actualizarAccesoriosLineasGrid: transition('g.x') failed");
        console.log(e);
      })
      .finally(function () {
        grupoLineas
          .select("g.x")
          .call(
            d3
              .axisBottom(escalaXGrid)
              .tickValues([2013, 2016, 2019, 2022])
              .tickFormat(d3.format("d"))
          );
        grupoLineas.select("g.x").selectAll("line").style("fill", "grey");
        grupoLineas
          .select("g.x")
          .selectAll("text")
          .style("fill", "grey")
          .style("font-size", "18px");
      });

    let domain = escalaYGrid.domain();
    let n = domain[1];
    grupoLineas
      .select("g.y")
      .transition("g.y")
      .duration(duracion)
      .call(
        d3.axisLeft(escalaYGrid).tickValues([0, n / 4, n / 2, (n * 3) / 4, n])
      )
      .end()
      .catch((e) => {
        console.log("actualizarAccesoriosLineasGrid: transition('g.y') failed");
        console.log(e);
      })
      .finally(function () {
        actualizarEscalaY(escalaYGrid, data);
        domain = escalaYGrid.domain();
        n = domain[1];
        grupoLineas
          .select("g.y")
          .call(
            d3
              .axisLeft(escalaYGrid)
              .tickValues([0, n / 4, n / 2, (n * 3) / 4, n])
          );
        grupoLineas.select("g.y").selectAll("line").style("fill", "grey");
        grupoLineas
          .select("g.y")
          .selectAll("text")
          .style("fill", "grey")
          .style("font-size", "18px");
      });

    grupoLineas
      .select("g.covid")
      .select("line")
      .transition("g.covid")
      .duration(duracion)
      .attr("x1", escalaXGrid(2020))
      .attr("x2", escalaXGrid(2020))
      .end()
      .catch((e) => {
        console.log(
          "actualizarAccesoriosLineasGrid: transition('g.covid') failed"
        );
        console.log(e);
      })
      .finally(function () {
        grupoLineas
          .select("g.covid")
          .select("line")
          .attr("x1", escalaXGrid(2020))
          .attr("x2", escalaXGrid(2020));
      });

    grupoLineas
      .select("g.covid")
      .select("text")
      .attr("x", escalaXGrid(2020) - 10);

    grupoLineas
      .select("g.covid")
      .select("text")
      .transition("g.covid_text")
      .duration(duracion)
      .attr("x", escalaXGrid(2020) - 10)
      .end()
      .catch((e) => {
        console.log(
          "actualizarAccesoriosLineasGrid: transition('g.covid_text') failed"
        );
        console.log(e);
      })
      .finally(function () {
        grupoLineas
          .select("g.covid")
          .select("text")
          .attr("x", escalaXGrid(2020) - 10);
      });
  }

  function reiniciarMiniGridCelda(params, codigo, celda) {
    let data = getViajesPorCodigo([codigo], params.viajesComparadosPorPais);
    let grupos = d3
      .select("div[data-container='story-3-3']")
      .selectAll("g.svg")
      .filter((d, i) => i === celda)
      .data(data);
    grupos.each(function (d) {
      let g = d3.select(this);
      g.select("g.y").call(unhide);
      actualizarAccesoriosLineasGrid(g, d);

      g.select("g.main")
        .selectAll("path.domestic")
        .data([d, d])
        .attr("d", pathDomesticoGrid);

      g.select("g.main")
        .selectAll("path.foreign")
        .data([d, d])
        .attr("d", pathForaneoGrid);

      iniciarLineasGrid(g);

      //wip
      let voronoiData = [];
      _.each(d, function (d) {
        voronoiData.push({
          x: escalaXGrid(d.year),
          y: escalaYGrid(d.domestic),
          value: formatter.format(d.domestic),
          type: "domestic",
          year: d.year,
        });
        voronoiData.push({
          x: escalaXGrid(d.year),
          y: escalaYGrid(d.foreign),
          value: formatter.format(d.foreign),
          type: "foreign",
          year: d.year,
        });
      });

      let delaunay = d3.Delaunay.from(
        voronoiData,
        (d) => d.x,
        (d) => d.y
      );

      let voronoi = delaunay.voronoi([
        0,
        0,
        lineasGridBox.content.width,
        lineasGridBox.content.height,
      ]);

      g.select("g.voronoi").selectAll("path").remove();

      g.select("g.voronoi")
        .selectAll("path")
        .data(voronoiData)
        .enter()
        .append("path")
        .attr("d", (d, i) => voronoi.renderCell(i))
        .attr("fill", "transparent")
        .attr("stroke", "transparent")
        .on("mouseover", crearTooltipVoronoi)
        .on("mouseout", deshacerTooltipVoronoi);
    });
  }

  function mostrarMiniGrid() {
    //TODO: Esconder todo loq ue va dentro
    d3.select("div[data-container='story-3-1']").call(hide);
    d3.select("div[data-container='story-3-2']").call(hide);
    d3.select("div[data-container='story-3-3']")
      .call(unhide)
      .selectAll("g")
      .call(unhide);
    iniciarMiniGrid();
  }

  function hide(el) {
    el.style("display", "none").style("opacity", 0);
  }

  function unhide(el) {
    el.style("display", null).style("opacity", null);
  }

  let definido = true;
  function mostrarCapas(clave, direccion, params) {
    capas.anterior = capas.actual;
    capas.actual = clave;

    if (capas.actual === null || capas.anterior === null) {
      definido = false;
    } else {
      definido = true;
    }

    if (definido) {
      if (
        capas.anterior == "base" &&
        capas.actual == "france" &&
        isReduced !== true
      ) {
        centrarFrancia();
        return 0;
      }
      if (
        capas.anterior == "france" &&
        capas.actual == "zoom" &&
        isReduced !== true
      ) {
        zoomFrancia(params);
        return 0;
      }
      if (
        capas.anterior == "zoom" &&
        capas.actual == "globe" &&
        isReduced !== true
      ) {
        mostrarGlobo();
        return 0;
      }
      if (
        capas.anterior == "globe" &&
        capas.actual == "arrows" &&
        isReduced !== true
      ) {
        //mostrarFlechas(params);
        startTimerGlobe();
        crearFlechas(params, true);
        return 0;
      }
      if (
        capas.anterior == "arrows" &&
        capas.actual == "sankey_france" &&
        isReduced !== true
      ) {
        mostrarSankeyInicial(params);
        return 0;
      }
      if (
        capas.anterior == "sankey_france" &&
        capas.actual == "sankey_eu" &&
        isReduced !== true
      ) {
        actualizarSankey(gSankey, sankeyPrincipal, params.sankeyUE);
        return 0;
      }
      if (
        capas.anterior == "sankey_eu" &&
        capas.actual == "sankey_charts" &&
        isReduced !== true
      ) {
        actualizarSankeysPorPais(params.sankeyUE);
        return 0;
      }
      if (
        capas.anterior == "sankey_charts" &&
        capas.actual == "line_charts" &&
        isReduced !== true
      ) {
        sankeysPorPaisALineas();
        return 0;
      }
      if (
        capas.anterior == "line_charts" &&
        capas.actual == "highlight_lines_1" &&
        isReduced !== true
      ) {
        mostrarMiniGrid();
        return 0;
      }
      if (
        capas.anterior == "highlight_lines_1" &&
        capas.actual == "highlight_lines_2" &&
        isReduced !== true
      ) {
        actualizarMiniGrid(params, ["EL", "IT", "PL", "SK"]);
        return 0;
      }
      if (
        capas.anterior == "highlight_lines_2" &&
        capas.actual == "highlight_lines_3" &&
        isReduced !== true
      ) {
        actualizarMiniGridCelda(params, "IE", 0);
        d3.select("div[data-container='story-3-3']")
          .selectAll("g.svg")
          .filter((d, i) => i === 1)
          .each(function (d) {
            terminarLineasGrid(d3.select(this), d);
          });

        d3.select("div[data-container='story-3-3']")
          .selectAll("g.svg")
          .filter((d, i) => i === 2 || i === 3)
          .each(function (d) {
            terminarLineasGrid(d3.select(this), d);
          })
          .call(hide);
        /*
        d3.select("div[data-container='story-3-3']")
          .selectAll("div[data-clave]")
          .filter((d, i) => i === 2 || i === 3)
          .call(hide);
          */
        return 0;
      }
      if (
        capas.anterior == "highlight_lines_3" &&
        capas.actual == "highlight_lines_4" &&
        isReduced !== true
      ) {
        reiniciarMiniGridCelda(params, "LT", 1);
        return 0;
      }
      /*
      if (capas.actual == "base") {
        return 0;
      }
      if (capas.actual == "france") {
        return 0;
      }
      if (capas.actual == "zoom") {
        return 0;
      }
      if (capas.actual == "globe") {
        return 0;
      }
      if (capas.actual == "arrows") {
        return 0;
      }
      if (capas.actual == "sankey_france") {
        return 0;
      }
      if (capas.actual == "sankey_eu") {
        return 0;
      }
      if (capas.actual == "sankey_charts") {
        return 0;
      }
      if (capas.actual == "line_charts") {
        return 0;
      }
      if (capas.actual == "highlight_lines_1") {
        return 0;
      }
      if (capas.actual == "highlight_lines_2") {
        return 0;
      }
      if (capas.actual == "highlight_lines_3") {
        return 0;
      }
      if (capas.actual == "highlight_lines_4") {
        return 0;
      }
      */
    }
  }

  function ocultarStory3_1() {
    let container = d3.select("[data-container=story-3-1]");
    container
      .transition("ocultarStory3_1")
      .duration(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("ocultarStory3_1: transition('ocultarStory3_1') failed");
        console.log(e);
      })
      .finally(function () {
        container.call(hide);
        gGlobo.call(hide);
        gMapa.call(hide);
        gMapa.select("g.countries").call(hide);
        gMapa.select("#france").call(hide);
        gMapa.select("g.departments").call(hide);
        gMapa.select("g.circles").call(hide);
        gMapa.select("g.trips").call(hide);
        gSankey.call(hide);
        gMapa.select("g.origin").call(hide);
        gMapa.select("g.trips").call(hide);
      });
  }
  window.ocultarStory3_1 = ocultarStory3_1;

  function mostrarStory3_1() {
    let container = d3.select("[data-container=story-3-1]");
    container
      .style("opacity", 0)
      .style("display", null)
      .transition("mostrarStory3_1")
      .duration(duracion)
      .style("opacity", 1)
      .end()
      .catch((e) => {
        console.log("mostrarStory3_1: transition('mostrarStory3_1') failed");
        console.log(e);
      })
      .finally(function () {
        container.call(unhide);
      });
  }
  window.mostrarStory3_1 = mostrarStory3_1;

  function ocultarStory3_2() {
    let container = d3.select("[data-container=story-3-2]");
    container
      .transition("ocultarStory3_2")
      .duration(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("ocultarStory3_2: transition('ocultarStory3_2') failed");
        console.log(e);
      })
      .finally(function () {
        container.call(hide);
        container.selectAll("g.origin").call(hide);
        container.selectAll("g.trips").call(hide);
        container.selectAll("g.name").call(hide);
        container.selectAll("g.x").call(hide);
        container.selectAll("g.y").call(hide);
        container.selectAll("g.covid").call(hide);
        container.selectAll("g.main").call(hide);
        container.selectAll("g.voronoi").call(hide);
      });
  }
  window.ocultarStory3_2 = ocultarStory3_2;

  function mostrarStory3_2() {
    let container = d3.select("[data-container=story-3-2]");
    container
      .style("opacity", 0)
      .style("display", null)
      .transition("mostrarStory3_2")
      .duration(duracion)
      .style("opacity", 1)
      .end()
      .catch((e) => {
        console.log("mostrarStory3_2: transition('mostrarStory3_2') failed");
        console.log(e);
      })
      .finally(function () {
        container.call(unhide);
      });
  }
  window.mostrarStory3_2 = mostrarStory3_2;

  function ocultarStory3_3() {
    let container = d3.select("[data-container=story-3-3]");
    container
      .transition("ocultarStory3_3")
      .duration(duracion)
      .style("opacity", 0)
      .end()
      .catch((e) => {
        console.log("ocultarStory3_3: transition('ocultarStory3_3') failed");
        console.log(e);
      })
      .finally(function () {
        container.call(hide);
        container.selectAll("g.name").call(hide);
        container.selectAll("g.x").call(hide);
        container.selectAll("g.y").call(hide);
        container.selectAll("g.covid").call(hide);
        container.selectAll("g.main").call(hide);
        container.selectAll("g.voronoi").call(hide);
      });
  }
  window.ocultarStory3_3 = ocultarStory3_3;

  function mostrarStory3_3() {
    let container = d3.select("[data-container=story-3-3]");
    container
      .style("opacity", 0)
      .style("display", null)
      .transition("mostrarStory3_3")
      .duration(duracion)
      .style("opacity", 1)
      .end()
      .catch((e) => {
        console.log("mostrarStory3_3: transition('mostrarStory3_3') failed");
        console.log(e);
      })
      .finally(function () {
        container.call(unhide);
      });
  }
  window.mostrarStory3_3 = mostrarStory3_3;

  //NOTE: https://observablehq.com/@d3/path-tween?collection=@d3/d3-transition
  function pathTween(d1, precision) {
    return function () {
      const path0 = this;
      const path1 = path0.cloneNode();
      path1.setAttribute("d", d1);
      const n0 = path0.getTotalLength();
      const n1 = path1.getTotalLength();

      // Uniform sampling of distance based on specified precision.
      const distances = [0];
      const dt = precision / Math.max(n0, n1);
      let i = 0;
      while ((i += dt) < 1) distances.push(i);
      distances.push(1);

      // Compute point-interpolators at each distance.
      const points = distances.map((t) => {
        const p0 = path0.getPointAtLength(t * n0);
        const p1 = path1.getPointAtLength(t * n1);
        return d3.interpolate([p0.x, p0.y], [p1.x, p1.y]);
      });

      return (t) => (t < 1 ? "M" + points.map((p) => p(t)).join("L") : d1);
    };
  }

  setup();
});
